<template>
  <div class="adver-continer">
    <div class="adver-but">
        <el-button size="mini" type="primary">新增banner</el-button>
    </div>
    <div class="adver-conter">
       <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      fixed
      prop="date"
      label="编号"
      width="150">
    </el-table-column>
    <el-table-column
      prop="name"
      label="banner名称"
      width="120">
    </el-table-column>
    <el-table-column
      prop="province"
      label="banner图片"
      width="120">
      <template slot-scope="scope">
                <img class="lable-img" :src="scope.row.thumb" alt="">
              </template>
    </el-table-column>
    <el-table-column
      prop="city"
      label="时间"
      width="120">
    </el-table-column>
    <el-table-column
      prop="address"
      label="banner状态"
      width="300">
    </el-table-column>
    <el-table-column
      prop="zip"
      label="是否关闭"
      width="120">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style  scoped>
.adver-conter{
  margin-top: 20px;
}
.adver-but{
margin-left:90% ;
}
</style>